<template>
    <div
        class="card-list"
        :data-columns="columns"
    >
        <h3
            v-if="!!$slots.title || title"
            class="card-list__title"
        >
            <slot name="title">{{ title }}</slot>
        </h3>

        <div class="card-list__items">
            <slot />
        </div>
    </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: null
    },

    columns: {
        type: Number,
        default: 1
    }
});
</script>

<style lang="less" src="./CardList.less"></style>
